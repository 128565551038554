import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './App.css';

import axios from 'axios';
import NeuronView from './NeuronView';


function Neuronpage() {
	const { neuronId, neuronName, sign } = useParams();

    const [neuronData, setNeuronData] = useState(null);

    useEffect(() => {
        const get_file = async () => {
            try {
                const response = await axios.get('https://neuron-backend.transluce.org/read_specific_file', {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    params: {
                        layer: neuronId,
                        neuron: neuronName,
                        sign: sign
                    }
                });
                setNeuronData(response.data);
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching file list:', error);
                setNeuronData(null);
            }
        }
        get_file();
    }, []);

      return (
        <div className="App">
            {neuronData && (
              <NeuronView neuronData={neuronData} isNegative={sign === "-"} />
            )}
        </div>
      );
}

export default Neuronpage;
