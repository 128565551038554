import React, { useState } from 'react';
import './App.css';

import { LOW_SCORES } from "./low_scores";
import { HIGH_SCORES } from "./high_scores";

function Homepage() {
  const [selectedLayer, setSelectedLayer] = useState(0);

  const formatDescription = (description) => {
    return description.replace(/\{\{(.*?)\}\}/g, '<u>$1</u>');
  };

  return (
    <div className="App">
      <h1>Neuron Descriptions for Llama 3.1-8B-Instruct</h1>
      <h4>Check out our <a href="https://transluce.org/neuron-descriptions" target="_blank" rel="noopener noreferrer">writeup</a> for details on how these descriptions were generated and scored!</h4>

      <select onChange={(e) => setSelectedLayer(Number(e.target.value))} style={{font: 'inherit'}}>
        {[...Array(32)].map((_, i) => (
          <option key={i} value={i} style={{font: 'inherit'}}>Neurons for Layer {i}</option>
        ))}
      </select>
      <table style={{borderCollapse: 'collapse', textAlign: 'left', width: '80%', margin: '20px auto'}}>
        <thead>
          <tr>
            <th colSpan="3" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 500, width: '50%' }}><b style={{fontSize: '16px'}}>Top 10 High-scoring Neurons</b></th>
            <th colSpan="3" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 500, width: '50%' }}><b style={{fontSize: '16px'}}>Bottom 10 Low-scoring Neurons</b></th>
          </tr>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 500 }}><b>Description</b></th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 500 }}><b>Neuron</b></th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 500 }}><b>Score</b></th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 500 }}><b>Description</b></th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 500 }}><b>Neuron</b></th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 500 }}><b>Score</b></th>
          </tr>
        </thead>
        <tbody>
          {[...Array(10)].map((_, i) => (
            <tr key={i}>
              <td style={{ border: '1px solid #ddd', padding: '8px', verticalAlign: 'top', cursor: 'pointer' }}
                  onClick={() => window.open(`https://neurons.transluce.org/${selectedLayer}/${HIGH_SCORES[selectedLayer][i].neuron}/${HIGH_SCORES[selectedLayer][i].sign == -1 ? '-' : '+'}`, '_blank')}
                  dangerouslySetInnerHTML={{ __html: formatDescription(HIGH_SCORES[selectedLayer][i].description) }}>
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px', verticalAlign: 'top', cursor: 'pointer' }} onClick={() => window.open(`https://neurons.transluce.org/${selectedLayer}/${HIGH_SCORES[selectedLayer][i].neuron}/${HIGH_SCORES[selectedLayer][i].sign == -1 ? '-' : '+'}`, '_blank')}>
                {HIGH_SCORES[selectedLayer][i].neuron}, {HIGH_SCORES[selectedLayer][i].sign == -1 ? '-' : '+'}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px', verticalAlign: 'top', cursor: 'pointer' }} onClick={() => window.open(`https://neurons.transluce.org/${selectedLayer}/${HIGH_SCORES[selectedLayer][i].neuron}/${HIGH_SCORES[selectedLayer][i].sign == -1 ? '-' : '+'}`, '_blank')}>
                {HIGH_SCORES[selectedLayer][i].score.toFixed(3)}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px', verticalAlign: 'top', cursor: 'pointer' }}
                  onClick={() => window.open(`https://neurons.transluce.org/${selectedLayer}/${LOW_SCORES[selectedLayer][i].neuron}/${LOW_SCORES[selectedLayer][i].sign == -1 ? '-' : '+'}`, '_blank')}
                  dangerouslySetInnerHTML={{ __html: formatDescription(LOW_SCORES[selectedLayer][i].description) }}>
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px', verticalAlign: 'top', cursor: 'pointer' }} onClick={() => window.open(`https://neurons.transluce.org/${selectedLayer}/${LOW_SCORES[selectedLayer][i].neuron}/${LOW_SCORES[selectedLayer][i].sign == -1 ? '-' : '+'}`, '_blank')}>
                {LOW_SCORES[selectedLayer][i].neuron}, {LOW_SCORES[selectedLayer][i].sign == -1 ? '-' : '+'}
              </td>
              <td style={{ border: '1px solid #ddd', padding: '8px', verticalAlign: 'top', cursor: 'pointer' }} onClick={() => window.open(`https://neurons.transluce.org/${selectedLayer}/${LOW_SCORES[selectedLayer][i].neuron}/${LOW_SCORES[selectedLayer][i].sign == -1 ? '-' : '+'}`, '_blank')}>
                {LOW_SCORES[selectedLayer][i].score.toFixed(3)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Homepage;
