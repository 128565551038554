import React, { useState } from 'react';
import './App.css';

import HomePage from './Homepage';
import Neuronpage from './Neuronpage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:neuronId/:neuronName/:sign" element={<Neuronpage />} />
      </Routes>
    </Router>
  );
}

export default App;
