import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';

import { CircleHelp } from 'lucide-react';


function NeuronView({ neuronData, isNegative }) {
  const [showPositive, setShowPositive] = useState(!isNegative);
  const [activations, setActivations] = useState(null);
  const [trueActivations, setTrueActivations] = useState(null);
  const [explanationIndex, setExplanationIndex] = useState(0);

  const [multiplier, setMultiplier] = useState(0);
  const [trueMultiplier, setTrueMultiplier] = useState(0);

  const [customExplanation, setCustomExplanation] = useState('');
  const [customActivations, setCustomActivations] = useState(null);
  const [customScore, setCustomScore] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [helpText, setHelpText] = useState('');
  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const [helpPopupPosition, setHelpPopupPosition] = useState({ x: 0, y: 0 });

  const formatDescription = (description) => {
    return description.replace(/\{\{(.*?)\}\}/g, '<u>$1</u>');
  };

  const computeColor = (float) => {
    let MIN_COLOR = null;
    let MAX_COLOR = null;
    if (!showPositive) {
      MIN_COLOR = [248, 117, 87, 0];
      MAX_COLOR = [255, 0, 0, 1];
    } else {
      MAX_COLOR = [118, 135, 250, 0.96];
      MIN_COLOR = [174, 196, 245, 0];
    }
    return `rgba(${MIN_COLOR.map((c, i) => c + (MAX_COLOR[i] - c) * float).join(',')})`;
  }

  const handleQuestionMarkHover = (text, event) => {
    setHelpText(text);
    setShowHelpPopup(true);
    setHelpPopupPosition({ x: event.clientX, y: event.clientY });
  };

  const handleQuestionMarkLeave = () => {
    setShowHelpPopup(false);
  };

  const submitExplanation = async () => {
    if (!neuronData.new) {
      return;
    }
    setIsSubmitting(true);
    setErrorMessage('');

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000);

    const startTime = performance.now();

    try {
      const response = await axios.post('https://neuron-backend.transluce.org/simulate', {
        explanation: customExplanation,
        layer: neuronData['layer'],
        neuron: neuronData['neuron'],
        sign: showPositive ? "+" : "-",
      }, {
        headers: {
          'Content-Type': 'application/json'
        },
        signal: controller.signal
      });

      const endTime = performance.now();
      console.log(`Request took ${endTime - startTime} milliseconds`);

      console.log(response.data);
      setCustomActivations(response.data.simulated_activations);
      setCustomScore(response.data.score);
    } catch (error) {
      console.error('Error submitting explanation:', error);
      if (error.name === 'AbortError') {
        setErrorMessage('We may be experiencing unusually high traffic :( Please try again later');
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
    } finally {
      clearTimeout(timeoutId);
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    if (explanationIndex === -1) {
      if (!customActivations) return;
      setActivations(customActivations);
      setMultiplier(1/Math.max(...customActivations.flat(Infinity)));
      return;
    }
    if (!neuronData) return;
    setActivations(neuronData.simulated_activations[explanationIndex]);
    setMultiplier(1/Math.max(...neuronData.simulated_activations[explanationIndex].flat(Infinity)));
    console.log(neuronData.simulated_activations[explanationIndex]);
  }, [neuronData, showPositive, explanationIndex, customActivations])

  useEffect(() => {
    if (!neuronData) return;
      let arr = [];
      for (let i = 0; i < neuronData.exemplar_tokens.length; i++) {
        arr.push(neuronData.exemplar_tokens[i][1]);
      }
      setTrueActivations(arr);
      setTrueMultiplier(1/Math.max(...arr.flat(Infinity)));
  }, [neuronData, showPositive])

  return (
    <>
      {activations && (
        <div>
          <div style={{ fontSize: '16px', fontWeight: 500, margin: '10px' }}>
            Layer {neuronData['layer']}, Neuron {neuronData['neuron']}, {showPositive ? 'Positive' : 'Negative'} Activations
            <span
              style={{ marginLeft: '5px', cursor: 'help' }}
              onMouseEnter={(e) => handleQuestionMarkHover("The MLP neurons in Llama 3 can have positive or negative activations. Neurons' behaviors in the positive and negative ranges are qualitatively different, so we consider them separately.", e)}
              onMouseLeave={handleQuestionMarkLeave}
            >
              <CircleHelp style={{width: '16px', height: '16px'}}/>
            </span>
            <div style={{ fontSize: '12px', marginTop: '5px' }}>
            Check out our <a href="https://transluce.org/neuron-descriptions" target="_blank" rel="noopener noreferrer">writeup</a> to learn how we generated descriptions and scored them!
            </div>
          </div>
          <div style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto'  }}>
            <div style={{ marginBottom: '20px', width: '100%' }}>
              <table style={{borderCollapse: 'collapse', textAlign: 'left', width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 500 }}>Description</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 500 }}>
                      Score
                      <span
                        style={{ marginLeft: '5px', cursor: 'help' }}
                        onMouseEnter={(e) => handleQuestionMarkHover("Description score represents how well a simulator can predict the neuron's activations when given the description (Pearson correlation between simulated and true activations). A higher score indicates a better description.", e)}
                        onMouseLeave={handleQuestionMarkLeave}
                      >
                        <CircleHelp style={{width: '16px', height: '16px'}}/>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {neuronData.explanation_summary.map((explanation, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: explanationIndex === index ? '#f2f2f2' : 'white',
                        cursor: 'pointer'
                      }}
                      onClick={() => setExplanationIndex(index)}
                    >
                      <td style={{ border: '1px solid #ddd', padding: '8px' }} dangerouslySetInnerHTML={{ __html: formatDescription(explanation[0]) }}>
                      </td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{explanation[1].toFixed(3)}</td>
                    </tr>
                  ))}
                  <tr
                    style={{
                      backgroundColor: explanationIndex === -1 ? '#f2f2f2' : 'white',
                      cursor: 'pointer'
                    }}
                    onClick={() => setExplanationIndex(-1)}
                  >
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                      Custom Description: {customExplanation}
                    </td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{customScore.toFixed(3)}</td>
                  </tr>
                </tbody>
              </table>
              <div style={{ textAlign: 'left' }}>
                <div style={{ position: 'relative' }}>
                  <textarea
                    style={{
                      display: 'block',
                      width: 'calc(100% - 26px)',
                      marginTop: '5px',
                      paddingBottom: '40px', // Add space for the button
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '12px',
                      fontSize: '14px',
                      font: 'inherit',
                      borderRadius: '0px',
                      border: '1px solid #ddd',
                      resize: 'none',
                      outline: 'none', // Remove default focus outline
                    }}
                    onFocus={(e) => e.target.style.border = '1px solid #ccc'} // Change border color on focus
                    onBlur={(e) => e.target.style.border = '1px solid #ddd'} // Revert border color on blur
                    placeholder="Enter your custom description here..."
                    value={customExplanation}
                    onChange={(e) => setCustomExplanation(e.target.value)}
                  />
                  <button
                    style={{
                      position: 'absolute',
                      bottom: '5px',
                      right: '5px',
                      font: 'inherit',
                    }}
                    onClick={submitExplanation}
                    disabled={isSubmitting}
                  >
                  {isSubmitting ? "Scoring Custom Description..." : (neuronData.new ? (
                    <>
                      Score Custom Description
                      <span
                        style={{ marginLeft: '5px', cursor: 'help' }}
                        onMouseEnter={(e) => handleQuestionMarkHover("Try your hand at submitting a custom description and seeing if you can score higher than the best description in the system!", e)}
                        onMouseLeave={handleQuestionMarkLeave}
                      >
                        <CircleHelp style={{width: '16px', height: '16px'}}/>
                      </span>
                    </>
                  ) : "CURRENTLY DISABLED - Submit Custom Description")}
                  </button>
                  {errorMessage && (
                    <div style={{ color: 'red', marginTop: '5px' }}>
                      {errorMessage}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ fontWeight: 500, padding: '8px', border: '1px solid #ccc' }}>
                    True Activations
                    <span
                      style={{ marginLeft: '5px', cursor: 'help' }}
                      onMouseEnter={(e) => handleQuestionMarkHover("The neuron's true activations are its values over top-activating input sequences. Read more about how top-activating sequences are computed in our writeup.", e)}
                      onMouseLeave={handleQuestionMarkLeave}
                    >
                      <CircleHelp style={{width: '16px', height: '16px'}}/>
                    </span>
                  </th>
                  <th style={{ fontWeight: 500, padding: '8px', border: '1px solid #ccc' }}>
                    Simulated Activations
                    <span
                      style={{ marginLeft: '5px', cursor: 'help' }}
                      onMouseEnter={(e) => handleQuestionMarkHover("A neuron description serves as a guide for predicting the neuron's behavior on input text. We use our fine-tuned simulator to predict a neuron's per-token activation values given the description, and use this to score descriptions.", e)}
                      onMouseLeave={handleQuestionMarkLeave}
                    >
                      <CircleHelp style={{width: '16px', height: '16px'}}/>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {neuronData.exemplar_tokens.map((exemplar, exemplarIndex) => (
                  <tr key={exemplarIndex}>
                    {[0, 1].map((columnIndex) => (
                      <td key={columnIndex} style={{ border: '1px solid #ccc', padding: '10px', textAlign: 'left' }}>
                        {exemplar[0].map((token, tokenIndex) => (
                          <span
                            key={tokenIndex}
                            style={{
                              marginRight: '0px',
                              backgroundColor: `${columnIndex === 0 ? computeColor(trueMultiplier*Math.abs(trueActivations[exemplarIndex][tokenIndex])) : computeColor(multiplier*Math.abs(activations[exemplarIndex][tokenIndex]))}`
                            }}
                          >
                            {token}
                          </span>
                        ))}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {showHelpPopup && (
              <div
                style={{
                  position: 'fixed',
                  top: helpPopupPosition.y + 20,
                  left: helpPopupPosition.x + 20,
                  backgroundColor: 'white',
                  border: '1px solid #ccc',
                  padding: '10px',
                  borderRadius: '5px',
                  boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                  zIndex: 1000,
                  maxWidth: '300px',
                }}
              >
                {helpText}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default NeuronView;
